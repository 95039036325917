<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-4 mt-8 d-flex ">
                <div class="col-md-11">
                  <h1>Medical Screening Types</h1>
                </div>
               <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="addNew" class="mr-2"/>
               </div>
              </div>
             <v-data-table
              loading-text="Fetching records... Please wait"
              :headers= 'headers'
              :items="categories"
             >
              <template v-slot:item.actions="{ items }" >
                <div class="d-flex">
                  <Button :btnType="'Cancel'" :label="'Delete'" />
                </div>
              </template>

             </v-data-table>

              <!-- confirm delete modal  -->
              <Dialog ref="addModal" :title="'Add Screening Type'" :width="400">
                <v-row>
                  <v-col cols="12">
                    <v-form ref="screeningType" v-model="valid">
                      <v-col cols="12">
                          <TextField v-model="formData.name" :label="'Name'"/>
                          <v-textarea :type="'textarea'" rows="2" v-model="formData.description" :label="'Description'"/>
                      </v-col>
                    </v-form>
                  </v-col>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Save'" @onClick="dialog = true" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel('addModal')" />
                </template>
              </Dialog>

               <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="saveCategory"/>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { medicalScreeningCategoryService } from '@/services'

export default {
  components: {
    Button,
    Dialog,
    TextField,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        description: ''
      },
      valid: false,
      dialog: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      categories: [],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Description',
          value: 'description'
        }
        // {
        //   text: 'Actions',
        //   value: 'actions'
        // }
      ]
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      this.$refs.addModal.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    openAddModal () {
      this.$refs.addModal.toggleModal()
    },
    saveCategory () {
      medicalScreeningCategoryService.saveMedicalScreeningCategory(this.formData).then((result) => {
        this.categories = result.data
        this.formData.name = ''
        this.formData.description = ''

        this.dialog = false

        this.showAlertMessage('Medical screening type successfuly saved', 'success')
      })
    },
    cancel (modal) {
      if (modal === 'addModal') {
        this.$refs.addModal.toggleModal()
      }
    }

  },
  mounted () {
    medicalScreeningCategoryService.getMedicalScreeningCategory().then(result => {
      this.categories = result.data
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
